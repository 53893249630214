<template>
	<div id="averageData">
		历史均值
	</div>
</template>

<script>
	export default {

	}
</script>

<style lang="scss">
</style>
